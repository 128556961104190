import React, {useEffect, useState} from 'react';
import CommonDropDown from "@/components/commonDropdown/CommonDropDown";
import styles from "./AddressDropDown.module.scss";

const AddressDropDown = (props) => {

  const {title, menuData, itemClick} = props;

  const renderItem = (item, index) => {
    console.log(item)
    return (
      <div className={styles.coinItem}>
        <div className={styles.title}>
          {item.address}
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className={styles.title}>{title}</div>
      <div className="h10"></div>
      <CommonDropDown menuData={menuData} renderItem={renderItem} itemClick={itemClick}></CommonDropDown>
    </div>
  );
};

AddressDropDown.defaultProps = {
  title: '選擇地址'
}

export default AddressDropDown;
