import React, {useEffect, useState} from 'react';
import AddressDropDown from "@/components/addressDropDown/AddressDropDown";
import commonApi from "@/service/commonApi";

const AddressChoose = (props) => {
  const {chooseAddress, ...rest} = props;
  const [optionNet, setOptionNet] = useState([]);

  useEffect(() => {
    commonApi.getAddress()
      .then(res => {
        setOptionNet(res.data.list);
        itemClickNet(res.data.list[0]);
      })
  }, []);

  const itemClickNet = (item, index) => {
    chooseAddress && chooseAddress(item);
  }

  return (
    <AddressDropDown menuData={optionNet} itemClick={itemClickNet}></AddressDropDown>
  );
};

export default AddressChoose;
