/* 战场 */
import { get, post} from "./request";

const api = {
    /* 产品列表 */
    shopList: (data) => (post( '/api/portal/shop/list',data)),
    buy: (data) => (post( '/api/portal/shop/buy',data)),
    createAddress: (data) => (post( '/api/portal/shop/address/create',data)),
    deleteAddress: (data) => (post( '/api/portal/shop/address/delete',data)),
    orderHistory: (data) => (post( '/api/portal/shop/order/history',data)),
    orderDelete: (data) => (post( '/api/portal/shop/order/delete',data)),
    orderCheck: (data) => (post( '/api/portal/shop/order/update',data))
};

export default api;
