/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-05-06 16:50:13
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-06-16 11:10:39
 * @FilePath: \weapp\wepay-h5\src\pages\cards\Record.js
 * @Description: 组件描述
 */
import styles from './Address.module.scss';
import React, {useRef, useState, memo} from 'react';
import CommonDialog from 'components/commonDialog/CommonDialog';

import CommonPage from "@/components/commonPage/page/CommonPage";
import CommonNavBar from 'components/commonNavBar/CommonNavBar';

import CommonPageScrollList from "@/components/commonPage/ScrollList/CommonPageScrollList";
import {useTranslation} from "react-i18next";  // 引用
import {toastError, toastHide, toastLoading, toastSuccess} from "utils/Toast";
import { ReactComponent as IconWalletRecordRecharge } from "assets/images/icon/WalletRecordRecharge.svg";
import { ReactComponent as IconWalletRecordWithdraw } from "assets/images/icon/WalletRecordWithdraw.svg";
import { ReactComponent as IconWalletRecordGift } from "assets/images/icon/WalletRecordGift.svg";
import { ReactComponent as IconWalletRecordConsume } from "assets/images/icon/WalletRecordConsume.svg";
import { ReactComponent as IconFilter } from "assets/images/icon/Filter.svg";
import mineApi from '@/service/mineApi';
import commonApi from '@/service/commonApi';
import shopApi from '@/service/shopApi';

export default memo(function Address() {
  let {t} = useTranslation();

  const refList = useRef(null);
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState(null);

  const getApiData = (page) => {
    let filterParam = {};
    if (filterData) {
      filterParam = {
        beginTime: filterData.timeEnd.start,
        endTime: filterData.timeEnd.end,
        maxAmount: filterData.inputMax,
        minAmount: filterData.inputMin,
        status: filterData.selectorInOut ? filterData.selectorInOut : '',
        type: filterData.selectorType || '',
        sort: filterData.selectorSort || '',
        coinName: filterData.selectorAssets || '',
      }
    }
    return commonApi.getAddress({
    });
  }

  // 钱包记录类型图标
  const getIcon = (type) => {
    switch (type) {
      case 0:
        return <IconWalletRecordRecharge className={styles.listIcon} />;
      case 1:
        return <IconWalletRecordWithdraw className={styles.listIcon} />;
      case 11:
        return <IconWalletRecordConsume className={styles.listIcon} />;
      case 12:
        return <IconWalletRecordConsume className={styles.listIcon} />;
      case 13:
        return <IconWalletRecordGift className={styles.listIcon} />;
      case 14:
        return <IconWalletRecordGift className={styles.listIcon} />;
      default:
        return <IconWalletRecordRecharge className={styles.listIcon} />;
    }
  }

  const header = () => {
    return (
      <div className={styles.header}>删除地址</div>
    )
  }

  const handleRemove = async (item) => {
    CommonDialog({
      type: 'confirm',
      title: header(),
      content: '是否删除地址？',
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
          shopApi.deleteAddress({
            id: item.id
          }).then(async (res) => {
            if (res.status === 200) {
              toastSuccess('删除成功');
            }
          })
      }
    })
  }

  const renderListItem = (item, index) => {

    return (
      <div className={`${styles.listItem} ${item.amount > 0 ? styles.curItem : styles.item}`}>
        <div className={styles.listContent}>
          <div className={styles.itemTitle}>
            <span className={styles.itemLabel}>{(item.address)}</span>
            <div className="flex justify-between gap12">
            {/*<img src={require('@/assets/images/mine/edit.png')} alt="edit" onClick={() => handleRemove(item)}/>*/}
            <img src={require('@/assets/images/mine/remove.png')} alt="remove" onClick={() => handleRemove(item)}/>
            </div>
          </div>
          <div className={styles.ItemFooter}>
            {item.firstName} {item.phone}
            {
              item.type === 1 && (
                // 手续费
                <span className={`${styles.fee} ${item.amount < 0 ? styles.negative : ''}`}>{t('lbl_withdraw_fee')}&nbsp;-{item.fee} {item.blockCoinName}</span>
              )
            }
          </div>
        </div>
      </div>
    )
  }

  // 标题栏右侧筛选按钮
  const renderFilterBtn = () => {
    return (
      <IconFilter className={styles.filterBtn} onClick={() => setShowFilter(true)} />
    )
  }

  return (
    <CommonPage title={t('lbl_my_address')} flex className={styles.record} header={<CommonNavBar>{t('lbl_my_address')}</CommonNavBar>}>
      <div className={styles.body}>
        <div className={styles.listBox}>
          <CommonPageScrollList
            ref={refList}
            renderListItem={renderListItem}
            getApiData={getApiData}>
          </CommonPageScrollList>
        </div>
      </div>

  </CommonPage>
  );
})
