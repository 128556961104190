import React, { memo, useEffect, useState, useRef } from 'react';

import { useLocation, useNavigate } from "react-router-dom";
import AddressChoose from "@/components/addressChoose/AddressChoose";
import styles from './Shop.module.scss';
import { useTranslation } from "react-i18next";
import {toastError, toastHide, toastLoading, toastSuccess} from "utils/Toast";
import CommonPage from "components/commonPage/page/CommonPage";
import CommonButton from "components/commonButton/CommonButton";
import CommonInput from "components/commonInput/CommonInput";
import FilterItemLayout from "components/filterItemLayout/FilterItemLayout";
import {useDispatch, useSelector} from "react-redux";
import gap from "assets/images/coin/gap.png"

import shopApi from "service/shopApi";
import classNames from 'classnames';
import md5 from "md5";


export default memo(function Battlefield() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id
  const shopInfo = location?.state?.info
  const type = location?.state?.type    // 0 bsc 1 积分
  
  const [param,setParam] = useState({id,pay_password:"",buyNum:1});
  const changeParams = (value,key) => {setParam(prevState => { prevState[key] = value;return {...prevState}})}

  const [address, setAddress] = useState(0);
  const [addressInfo, setAddressInfo] = useState({});

  const rareType = useSelector(state => state.app.heroLevel );
  const rare = rareType.find(item=>item.level == shopInfo.level)||rareType.find(items=>items.level === "1")

  const shopBuy = ()=>{
    toastLoading();
    console.log(addressInfo)
    if (!addressInfo) {
      toastError("请选择收货地址");
      return
    }
    shopApi.buy({...param,
      addressId: address,
      title: shopInfo.title,
      avatar: shopInfo.avatar,
      description: shopInfo.description,
      address: addressInfo.address,
      username: addressInfo.firstName,
      phone: addressInfo.phone,
      pay_amount: type ? shopInfo.bscPrice : shopInfo.scorePrice,
      pay_type: type,
      pay_password:md5( param.pay_password )}).then(res => {
        toastSuccess(res.message)
        navigate("/mine/shop")
    }).catch(err => {
        toastHide();
    })
  }

  useEffect(() => {
    //getMyNftList()
  }, []);
  console.log(shopInfo.avatar)

  return (
    <CommonPage tt={t('lbl_battlefield')}  flex  title={ t('lbl_product_buy')}> 
      <img src={require('@/assets/images/nft/frame-top.png')} alt="frame-top" />
      <div className={styles.bg}>
        <div className='flex justify-center'>
          <div className={classNames(styles.buy_grid_item, styles[rare.style])} style={{width: "45%", "backgroundImage": `url(${shopInfo.avatar}),linear-gradient(180deg, #123556 0%, #071A2C 100%)`}} />
        </div>
        <div className={classNames(styles.intro, 'text-16 line15 text-center')} >
          <div>{shopInfo.name}</div>
        </div>
      </div>
      <img src={require('@/assets/images/nft/frame-bottom.png')} alt="" />
      <div className={ classNames(styles.intro,styles.borderBottom) }>
        <div className='flex justify-between text-12'>
          <span className='text-aliceBlue'>{t("lbl_market_price")}</span>
          { type === 0 ? <span>{shopInfo.bscPrice + ' CAP'} </span> :
          <span>{shopInfo.scorePrice + ' ' + t('lbl_score')} </span>}
        </div>
      </div>

      <div className={classNames(styles.intro,styles.borderBottom)} style={{padding:"16px 0px"}}>
        <div className='text-16 text-aliceBlue text-center'>{t("pool.actualPrice")}</div>
        <div className='flex justify-center align-end text-24 text-bold ' style={{color: 'rgba(1, 255, 125, 1)'}}>
          {type === 0 ? <img src={gap} alt='gap' className={styles.cap}/> : <></>}
          <p>
          { type === 0? shopInfo.bscPrice + ' CAP' : t('lbl_score') + ': ' + shopInfo.scorePrice }
          </p>
        </div>
      </div>
      <div className="flex justify-between">
        <div style={{width: '50%'}} className={styles.itemSelect}>
          <AddressChoose chooseAddress={(res => {
            setAddressInfo(res)
            res && setAddress(res.id)
          })} />
        </div>
        <CommonButton style={{width: '40%', marginTop: '35px'}} onClick={()=>navigate("/mine/address/create")}>
          新增地址
        </CommonButton>
      </div>

      <div className={styles.intro}>
        <FilterItemLayout title={t('pool.password')}>
          <CommonInput type='password' value={param.pay_password} placeholder={t('pool.passwordInput')} onChange={val=>changeParams(val,"pay_password") }/>
        </FilterItemLayout>
      </div>

      { type === 0?
        <CommonButton shadow onClick={ shopBuy }>{t("pool.buy")}</CommonButton>:
        <CommonButton shadow onClick={ shopBuy }>{t("pool.score.buy")}</CommonButton>
      }

    </CommonPage>
  );
})
