import React, {useEffect, useState} from 'react';
import CoinDropDown from "@/components/coinDropDown/CoinDropDown";
import commonApi from "@/service/commonApi";
import {useTranslation} from "react-i18next";

const NetWorkChoose = (props) => {
  const {chooseNetWork, ...rest} = props;
  const [optionNet, setOptionNet] = useState([]);
  let {t} = useTranslation();

  useEffect(() => {
    commonApi.getChainNet()
      .then(res => {
        setOptionNet(res.data);
        itemClickNet(res.data[0]);
      })
  }, []);

  const itemClickNet = (item, index) => {
    chooseNetWork && chooseNetWork(item);
  }

  return (
    <CoinDropDown tt='選擇主網' title={t('lbl_choose_mainnet')} coinData={optionNet} itemClick={itemClickNet} {...rest}></CoinDropDown>
  );
};

export default NetWorkChoose;
