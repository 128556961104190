/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-05-06 16:50:13
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-06-16 11:10:39
 * @FilePath: \weapp\wepay-h5\src\pages\cards\Record.js
 * @Description: 组件描述
 */
import styles from './Order.module.scss';
import React, {useRef, useEffect, useState, memo} from 'react';
import CommonDialog from 'components/commonDialog/CommonDialog';

import CommonPage from "@/components/commonPage/page/CommonPage";
import CommonNavBar from 'components/commonNavBar/CommonNavBar';

import CommonPageScrollList from "@/components/commonPage/ScrollList/CommonPageScrollList";
import {useTranslation} from "react-i18next";  // 引用
import { Space } from "antd-mobile";
import {toastSuccess} from "utils/Toast";
import gap from "assets/images/coin/gap.png"
import shopApi from '@/service/shopApi';

export default memo(function Address() {
  let {t} = useTranslation();

  const refList = useRef(null);

  const getApiData = () => {
    return shopApi.orderHistory({
    });
  }

  useEffect(() => {
    refList?.current.refreshApiData();
  }, []);

  const header = (title) => {
    return (
      <div className={styles.header}>{title}</div>
    )
  }

  const handleCheck = async (item) => {
    console.log(item)
    CommonDialog({
      type: 'confirm',
      title: header('提示'),
      content: '确认收货？',
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
        shopApi.orderCheck({
          id: item.id,
          status: 2
        }).then(async (res) => {
          if (res.status === 200) {
            refList?.current.refreshApiData();
            toastSuccess('确认收货成功');
          }
        })
      }
    })
  }

  const handleShow = async (item) => {
    console.log(item)
    CommonDialog({
      type: 'confirm',
      title: header('提示'),
      content: '物流单号:' + item.logisticsNo,
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
      }
    })
  }

  const handleRemove = async (item) => {
    console.log(item)
    CommonDialog({
      type: 'confirm',
      title: header('提示'),
      content: '是否删除订单？',
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
          shopApi.orderDelete({
            id: item.id
          }).then(async (res) => {
            if (res.status === 200) {
              toastSuccess('删除成功');
              refList?.current.refreshApiData();
            }
          })
      }
    })
  }

  const renderListItem = (item, index) => {
    console.log(item)

    return (
      <div className={`${styles.listItem} ${item.amount > 0 ? styles.curItem : styles.item}`}>
        <div className={styles.listContent}>
          <div className={styles.itemTitle}>
            <span className={styles.itemLabel}>{(item.createdTime)}</span>
            <div className="flex justify-between gap12">
              {item.status !== "2" && <div className='text-16 text-bold'>等待收货</div>}
              {item.status === "2" && <div className='text-16 text-bold text-green'>交易完成</div>}
              <img src={require('@/assets/images/mine/remove.png')} alt="remove" onClick={() => handleRemove(item)}/>
            </div>
          </div>
          <div className={styles.ItemFooter}>
            <img src={item.avatar} width="100px" height="100px"></img>
            <div className='flex flex-direction'>
              <div className='text-16 marginLeft10 text-bold'>{item.title}</div>
              <div className='text-12 marginLeft10'>{item.description}</div>
              <div className={styles.intro}>
                <div className={styles.coin}>
                  {item.payType === 0 ? <img src={gap} alt='gap' /> : <img src={require('@/assets/images/ecology.png')} alt='ecology' className={styles.iconCoin} />}
                  <span className='text-20'>{item.payAmount}</span>
                </div>
              </div>
            </div>
          </div>

          {item.status !== "2" && <div className={styles.Footer}>
            {item.logisticsNo && <div className={styles.gridItem}>
              <span className='text-10 text-bold green' onClick={() => handleShow(item)}>查看物流</span>
            </div>}
            <div className={styles.gridItem}>
              <span className='text-10 text-bold green' onClick={() => handleCheck(item)}>确认收货</span>
            </div>
          </div>}
        </div>
      </div>
    )
  }

  return (
    <CommonPage title={t('lbl_my_address')} flex className={styles.record} header={<CommonNavBar>{t('lbl_my_order')}</CommonNavBar>}>
      <div className={styles.body}>
        <div className={styles.listBox}>
          <CommonPageScrollList
            ref={refList}
            renderListItem={renderListItem}
            getApiData={getApiData}>
          </CommonPageScrollList>
        </div>
      </div>
  </CommonPage>
  );
})
