/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-06 09:09:49
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-03-10 13:35:48
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\battlefield\Battlefield.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useEffect, useState } from 'react';

import { useLocation, useNavigate } from "react-router-dom";
import styles from './Shop.module.scss';
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";

import CommonPage from "components/commonPage/page/CommonPage";
import CommonButton from "components/commonButton/CommonButton";
import CommonCheckbox from "components/commonCheckbox/CommonCheckbox";
import gap from "assets/images/coin/gap.png"
import classNames from 'classnames';

export default memo(function Battlefield() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id
  const info = location?.state?.info
  const [check, setCheck] = useState(false)
  
  const rareType = useSelector(state =>  state.app.heroLevel);
  const rare = rareType.find(item=>item.level == info.level) || rareType.find(items=>items.level === "1")

  useEffect(() => {
  }, []);

  return (
    <CommonPage tt={t('lbl_battlefield')}  flex  title={t('lbl_product_detail')}>
      <div
        className={classNames(styles.nft, styles[rare?.style])}
        style={{"backgroundImage": `url(${info.avatar}),linear-gradient(180deg, #123556 0%, #071A2C 100%)`}}
      >
      </div>
      <div className={styles.intro}>
        <div className='flex align-center justify-between text-20 text-bold'>
          <div className='text-bold'>{info.title}</div>
        </div>
      </div>
      <p className={classNames(styles.intro, styles.border, 'text-12 text-aliceBlue')}>
        {info.description}
      </p>
      {
        <>
          <div className={styles.intro}>
            <div className={styles.coin}>
              <img src={gap} />
              <span className='text-24'>{info.bscPrice.toFixed(2)}</span>
            </div>
            <div className={styles.coin}>
              <span className='text-10 text-orange'>积分 </span>
              <span className='text-10 text-orange marginLeft4'>{info.scorePrice.toFixed(2)}</span>
            </div>
          </div>
          <div className='flex justify-between'>
            <p className={classNames(styles.intro,styles.border,'text-12 text-aliceBlue')}>
              销量 {info.sellCount}
            </p>
            <p className={classNames(styles.intro,styles.border,'text-12 text-aliceBlue')}>
              库存 {info.inStock}
            </p>
            <p className={classNames(styles.intro,styles.border,'text-12 text-aliceBlue')}>
              浏览量 {info.viewsCount ? info.viewsCount : 0}
            </p>
          </div>
          <div className='flex justify-end top10'>
            <CommonCheckbox onChange={(checked) => {setCheck(checked)}}></CommonCheckbox>
            <div className='text-12 text-bold top2 text-aliceBlue'>使用生态积分</div>
          </div>
          <div className='top10'>
            <CommonButton shadow onClick={()=>navigate('/mine/shop/buy',{state:{id, check,info,type: check ? 1 : 0}})}>{t("pool.buy")}</CommonButton>
          </div>
        </>
      }
      </CommonPage>
    );
  })
