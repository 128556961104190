/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  "hello": "Hello",
  "common": {
    "register": "Register Account",
    "login": "Login",
    "linkWallet": "Connect Wallet",
    "next": "Next",
    "skip": "Skip",
    "opening": "Comming soon",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "reset": "Reset",
    "approve": "Approve...",
    "approveUsdt": "Approve USDT",
    "success": "Success",
    "failure": "Failure",
    "address": "Address",
    "inputAddress": "Input address",
    "copy": "Copy",
    "nextStep": "Next step",
    "back": "Back",
    "complete": "Complete",
    "logout": "Logout",
    "tip": "Tip",
    "lbl_no_data": "No Data",
  },
  "splash": {
    "connectWallet": "Welcome to NewWorld",
    "connectWalletDesc": "Please connect your wallet!",
    "1": "Please link your digital wallet to start using our service.",
    "2": "Global One-Stop Credit Card and Payment Solution.",
    "3": "Get a physical credit card and experience seamless global payments.",
    "4": "Easily increase your credit card limit through mainstream cryptocurrency financial management quotas.",
    "5": "Earn rebate rewards and effortlessly accumulate points for international use.",
    "6": "Get Started"
  },
  'pool':{
    "history": "Details",
    "record": "Details",
    "rule": "Rules",
    "prop": "prop",
    "explore": "Start",
    "exploration": "Stop",
    "exploredTime": "Explored Time",
    "none": "No NFT owned",
    "level": "Level",
    "active": "Activate",
    "activating": "Working",
    "waiting": "Waiting",
    "myProduce": "Capacity ",
    "totalIncome": "Total Earning",
    "settleContribution": "Current settleable contribution value",
    "totalContribution": "Total contribution value obtained",
    "winRate": "Current win rate",
    "upgrade": "Upgrade",
    "buy": "Buy",
    "time": "time",
    "state": "state",
    "levelNum": "Number of levels",
    "contributionScore": "Contribution Score",
    "earn": "income",
    "upgradation": "upgrade",
    "score.buy":"score buy",
    "coinPrice": "{{tokenName}} Price",
    "currentCoinPrice": "Current coin price",
    "password": "Password",
    "passwordInput": "Please enter the password",
    "actualPrice": "Actual payment price",
    "countdown": "Countdown",
    "number": "quantity",
    "tips": "50% of the U paid by users when purchasing NFTs is exchanged for the corresponding {{tokenName}} value based on the collected {{tokenName}} coin price.",
    "day": "Day",
    "night": "At night",
    "allDay": "All day",
    "activationTime": "The activatable time is",
    "progress": "On the battlefield",
    "successExit": "Successfully exited",
    "failExit": "Failed to exit",
    "activeExit": "Manually exit",
    "income": "income"
  },
  "nft":{
    "myNFT": "My NFT",
    "buyHero": "Purchase hero NFTs",
    "updateHero": "Upgrade hero NFT",
    "buySkin": "Purchase Material NFTs",
    "rare": "rarity",

    "R": "Normal",
    "SR": "Advanced",
    "SSR": "Epic",
    "name": "NFT name",
    "no": "Number",
    "sort": "Sort",
    "type": "type",


    "hero":"Hero",
    "heroNFT": "Hero NFT",
    "heroR": "Ordinary Hero",
    "heroSR": "Advanced Hero",
    "heroSSR": "Epic Hero",

    "skin":"Material",
    "skinNFT": "Material NFT",
    "skinR": "ordinary Material",
    "skinSR": "Advanced Material",
    "skinSSR": "Epic Material",

    "hot":'Hot selling',
    "upgradation": "Go to upgrade",

    "extract": "Extract",
    "compose": "Composite",
    "transfer": "Transfer",

    "stock":"Stock",
    "price":"Price",
    "upgradeTip": "After upgrading the hero in this level, the new hero will take effect in the next exploration.",

  },
  "lbl_home": "Home",
  "lbl_sort": "sort",
  "lbl_sail_sort": "sailed",
  "lbl_price_sort": "price",
  "lbl_mine": "Mine",
  "lbl_battlefield": "Battlefield",
  "lbl_ranking": "Ranking",
  "lbl_mining_pool": "Mining Pool",
  "lbl_send_code": "Send Code",
  "lbl_chooseLanguage": "Choose Language",
  "lbl_placeholder_email": "Email Address",
  "lbl_placeholder_emailcode": "Email Verification Code",
  "lbl_placeholder_password": "Set Password",
  "lbl_placeholder_confirm_password": "Confirm Password",
  "lbl_placeholder_invite_code": "Invitation Code (Required)",
  "lbl_placeholder_promote_code": "Promote Code (Required)",
  "lbl_placeholder_username": "Username",
  "lbl_placeholder_nickname": "Nickname",
  "msg_empty_invite_code": "Invitation code cannot be empty",
  "msg_empty_promote_code": "Promotion code cannot be empty",
  "msg_empty_email": "Email address cannot be empty",
  "msg_empty_emailcode": "Email verification code cannot be empty",
  "msg_empty_password": "Password cannot be empty",
  "msg_empty_confirm_password": "Confirm password cannot be empty",
  "msg_incorrect_confirm_password": "The two passwords entered are inconsistent",
  "msg_incorrect_password": "The password is 8-16 digits, including letters, uppercase and lowercase letters and numbers",
  "msg_incorrect_email": "Please check if the email address is correct",
  "lbl_register_success": "Registration success",
  "msg_register_success": "Account registration successful",
  "msg_register_success_desc": "Start your NewWorld journey!",

  "lbl_my_account": "Account",
  "lbl_modify_nickname": "Change Nickname",
  "lbl_modify_password": "Change Password",
  "lbl_account_management": "Account Management",
  "lbl_current_account": "Current Account",
  "lbl_account_list": "Account List",
  "lbl_main_account": "Main Account",
  "lbl_create_sub_account": "Create Sub Account",
  "lbl_placeholder_nickname_2_14": "Nickname (2-14 characters)",
  "lbl_sub_account_desc": "*All sub-accounts share the password of the main account",
  "lbl_delete_sub_account": "Delete Sub Account",
  "lbl_delete_sub_account_confirm": "Are you sure you want to delete the sub-account?",
  "lbl_switch_sub_account": "Switch Sub Account",
  "lbl_switch_account": "Switch Account",
  "lbl_switch_account_desc": "Switch to use account as ",
  "lbl_my_invitation": "Invitation",
  "lbl_invitation_qrcode": "Invitation QR Code",
  "lbl_my_invitation_code": "My Invitation Code",
  "lbl_invitation_link": "Invitation Link",
  "lbl_invitation_rules": "Invitation Rules Explanation",
  "lbl_language_switch": "Language",
  "msg_password_changed": "Password changed successfully",
  "msg_incorrect_nickname": "Please enter a nickname between 2-14 characters",
  "msg_nickname_changed": "Nickname changed successfully",
  "lbl_create_sub_account_tip": "*All sub-accounts share the password of the main account",
  "msg_logout_confirm": "Are you sure you want to log out?",
  "msg_copy_success": "Copy successful",
  "lbl_invite_tip": "*Invite friends to register and get rewards",
  "msg_username": "Please enter username",
  "msg_phone": "Please enter phone",
  "msg_address_detail": "Please enter detail address",

  "lbl_my_wallet": "My Wallet",
  "lbl_my_score": "My Score",
  "lbl_contribution_score": "Contribute Score",
  "lbl_contribution_score_desc": "Accumulated value from space battles",
  "lbl_team_score": "Team Score",
  "lbl_team_score_desc": "Contribution under the umbrella and personal statistics contribute to the team score. Rankings are based on the total contribution points.",
  "lbl_my_assets": "My Assets",
  "lbl_my_power": "My Power",
  "lbl_total_power": "Total Power",
  "lbl_individual": "Individual",
  "lbl_peer": "Peer",
  "lbl_community": "Community",
  "lbl_share_1": "Share1",
  "lbl_share_2": "Share2",
  "lbl_share_3": "Share3",
  "lbl_withdraw": "Withdraw",
  "lbl_recharge": "Recharge",
  "lbl_wallet_record": "Records",
  "lbl_choose_mainnet": "Select Mainnet",
  "lbl_choose_address": "Select Address",
  "lbl_create_address": "Create Address",
  "lbl_choose_currency": "Select Currency",
  "lbl_withdraw_address": "Withdrawal Address",
  "lbl_withdraw_amount": "Withdrawal Amount",
  "lbl_balance": "Balance",
  "lbl_withdraw_fee": "Fee",
  "lbl_actual_account": "Actual Amount Received",
  "lbl_network": "Network",
  "lbl_address": "Address",
  "lbl_token": "Token",
  "lbl_estimated_time": "Estimated Time",
  "lbl_recharge_tip": "*Please only recharge USDT through the BNB network. Using other methods may result in loss of assets.",
  "lbl_input_wallet_address": "Enter Wallet Address",
  "msg_empty_wallet_address": "Wallet address cannot be empty",
  "lbl_input_min_withdraw_amount": "Minimum Withdrawal: {{amount}} USDT",

  "lbl_typeInOut": "Income/Expenses",
  "lbl_type": "Type",
  "lbl_time": "Time",
  "lbl_amount": "Amount",
  "lbl_assets": "Assets",
  "lbl_sort": "Sort",
  "lbl_startTime": "Start Time",
  "lbl_endTime": "End Time",
  "lbl_max": "Max",
  "lbl_min": "Min",
  "lbl_income": "Income",
  "lbl_expenditure": "Expenses",
  "lbl_receive": "Receive",
  "lbl_consume": "Consume",
  "lbl_timeAsc": "Time Ascending",
  "lbl_timeDesc": "Time Descending",
  "lbl_soon": "Coming soon",

  "lbl_nft_market": "NFT Market",
  "lbl_market_list": "Market",
  "lbl_market_price": "price",
  "lbl_product_buy": "buy product",
  "lbl_product_detail": "product detail",
  "lbl_online_market": "Online Market",
  "lbl_my_props": "My Props",
  "lbl_my_order": "My Orders",
  "lbl_my_address": "Address",
  "lbl_blind_box_market": "Box Market",
  "lbl_my_team": "My Team",
  "msg_create_sub_account_success": "Sub-account created successfully",
  "msg_verify_code_sent": "Verification code sent",
  "msg_incorrect_phone": "Please check if the phone number is correct",
  "lbl_get_code_again": "Resend",
  "msg_change_password_success": "Password changed successfully",
  "lbl_team_ranking": "Team Ranking",
  "lbl_upgrade": "Upgrade",
  "lbl_price": " Price",
  "lbl_my_produce": "Capacity",
  "lbl_total_income": "Total Earnings",
  "lbl_second": "Second",
  "lbl_third": "Third",
  "lbl_first": "First",
  "msg_switch_account_success": "Account switched successfully",
  "lbl_go_to_buy_nft": "Go to buy NFT",

  "msg_input_min_value": "Please enter a valid minimum amount",
  "msg_withdraw_success": "Withdrawal successful",
  "msg_input_numbers_only": "Numbers only",
  "msg_withdraw_amount_not_empty": "Withdrawal amount cannot be empty",

  "lbl_buy": "Buy",
  "lbl_pool_income": "Pool Earnings",
  "lbl_battlefield_income": "Battlefield Earnings",
  "lbl_compote_income": "Compote Earnings",
  "lbl_community_income": "Community Earnings",
  "lbl_airdrop_income": "Airdrop",
  "lbl_share1": "share1",
  "lbl_share2": "share2",
  "lbl_share3": "share3",
  "lbl_sort_time_asc": "Time Ascending",
  "lbl_sort_time_desc": "Time Descending",

  "lbl_sort_earn_asc": "Earn Ascending",
  "lbl_sort_earn_desc": "Earn Descending",
  "lbl_sort_score_asc": "Score Ascending",
  "lbl_sort_score_desc": "Score Descending",
  "lbl_sort_id_asc": "Id Ascending",
  "lbl_sort_id_desc": "Id Descending",
  "lbl_sort_level_asc": "Level Ascending",
  "lbl_sort_level_desc": "Level Descending",
  "lbl_sort_price_asc": "Price Ascending",
  "lbl_sort_price_desc": "Price Descending",
  "lbl_sort_sell_asc": "Sell Ascending",
  "lbl_sort_sell_desc": "Sell Descending",
  "lbl_input_nft_name": "Enter NFT name",
  "lbl_input_nft_id": "Enter NFT id",

  "lbl_withdraw_ing": "Withdrawal in progress",
  "lbl_withdraw_success": "Withdrawal successful",
  "lbl_withdraw_fail": "Withdrawal failed",
  "lbl_team_consume": "Team Total Consume",
  "lbl_my_team_num": "My Team Member({{num}} person)",
  "lbl_produce_introduce": "When the user purchases NFT, 50% of the U paid is converted into the corresponding {{tokenName}} value according to the collected {{tokenName}} coin price",

  "lbl_my_level": "My Level",
  "lbl_my_community_capacity": "My Community Capacity",
  "lbl_my_capacity_bonus": "My Capacity Bonus",
  "lbl_ecology_score": "Ecological Score",
  "lbl_modify_avatar": "Change Avatar",
  "lbl_copy_address": "Copy Address",
  "lbl_disconnect": "Disconnect",
  "lbl_exchange": "Exchange",
  "lbl_actual_amount": "Actual Amount",
  "lbl_exchange_num": " Exchange Amount",
  "lbl_current_price": "Current Price",
  "lbl_input_exchange_num": "Enter Exchange Amount",
  "lbl_password": "Password",
  "lbl_input_password": "Enter Password",
  "lbl_exchange_record": "Exchange Record",
  "msg_exchange_success": "Exchange Success",
  "lbl_user_id": "User ID",
  "msg_connect_wallet": "Please connect your wallet first",
  "lbl_unsupported": "Unsupported",

  "lbl_my_community": "My Community",
  "lbl_referrer_num": "Referrer Number",
  "lbl_my_subordinate_performance": "My Subordinate Performance",
  "lbl_my_subordinate_num": "My Subordinate Total Number",
  "lbl_my_subordinate_consume": "Subordinate Total Consume",
  "lbl_my_subordinate_list": "My Subordinate List",
  "lbl_my_referrer_consume": "Referrer Total Consume",
  "lbl_my_subordinate_performance_total": "Subordinate Total Performance",
  "lbl_my_consume": "My Total Consume",
  "lbl_my_referrer_num": "My Referrer Number",
  "lbl_my_referrer_consume_total": "My Referrer Total Consume",
  "lbl_subordinate_level": "{{level}} Level Subordinate",
  "lbl_id_search": "ID Search",
  "lbl_id_search_tip": "*Can only query your subordinate user ID",
  "lbl_input_subordinate_id": "Enter your subordinate ID",
  "lbl_check_level_performance": "Check Level Performance",
  "lbl_input_level": "Enter Level",
  "lbl_max_level": "Current Maximum Level: {{level}}",
  "msg_input_level": "Please enter a valid level, only integers are allowed",
  "lbl_community_setting": "Community Setting",
  "lbl_modify_name": "Change Name",
  "lbl_modify_icon": "Change Icon",
  "lbl_input_new_team_name": "Enter New Team Name",
  "msg_incorrect_team_name": "Please enter a valid team name, 2-14 characters",
  "msg_modify_team_name_success": "Team name changed successfully",
  "lbl_my_subordinate_num_level": "Total number of people in my {{level}} level",
  "lbl_my_subordinate_consume_level": "Accumulated consumption amount for level {{level}}",
  "lbl_my_subordinate_consume_level_tip": "*Only calculates the total accumulated consumption amount of all users at the current level",
  "lbl_my_subordinate_level": "My {{level}} level",
  "lbl_people": " Person",
  "lbl_username": "username",
  "lbl_phone": "phone",
  "lbl_address_detail": "address",
  "lbl_is_default": "default",
  "msg_level_over_max": "The level entered exceeds the maximum level",
  "lbl_placeholder_account": "Please enter 5-16 characters.",
  "msg_empty_account": "The username cannot be empty",
  "msg_incorrect_account": "Please enter a valid Username",
  "lbl_score":"score",

}
