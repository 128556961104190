/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-06 09:09:49
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-03-10 13:31:00
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\battlefield\Battlefield.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useEffect, useState } from 'react';
import {Tabs} from "antd-mobile";

import { useNavigate } from "react-router-dom";
import styles from './Shop.module.scss';
import { useTranslation } from "react-i18next";
import {toastError, toastHide, toastLoading, toastSuccess} from "utils/Toast";
import {useSelector} from "react-redux";

import { Swiper,Form,SpinLoading } from "antd-mobile";
import CommonPage from "components/commonPage/page/CommonPage";
import CommonNavBar from "components/commonNavBar/CommonNavBar";
import CommonPopup from 'components/commonPopup/CommonPopup';
import CommonInput from "components/commonInput/CommonInput";
import CommonSelector from "components/commonSelector/CommonSelector";
import CommonButton from "components/commonButton/CommonButton";

import IconFilter from "components/icon/IconFilter"
import gap from "assets/images/coin/gap.png"
import classNames from 'classnames';
import nftApi from "service/nftApi";
import shopApi from "service/shopApi";

export default memo(function Shop() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [filterVisible,setFilterVisible] = useState(false);
  const [param,setParam] = useState({pageNo:1,pageSize:20});
  const [list,setList] = useState([])

  const rareType = useSelector(state =>  state.app.heroLevel);
  
  const sortType=[
    {label:t("lbl_sort_price_asc"),value:"1",filter:{"orderBy":"bsc_price","orderType":"ASC"}},
    {label:t("lbl_sort_price_desc"),value:"2",filter:{"orderBy":"bsc_price","orderType":"DESC"}},
    {label:t("lbl_sort_sell_asc"),value:"3",filter:{"orderBy":"sell_count","orderType":"ASC"}},
    {label:t("lbl_sort_sell_desc"),value:"4",filter:{"orderBy":"sell_count","orderType":"DESC"}},
  ];

  const nftType=[
    {label:t("nft.hero"),value:1},
    {label:t("nft.skin"),value:0},
  ];

  useEffect(() => {
     getNftList();
  }, []);

  useEffect(() => {
    const data = form.getFieldsValue(true)
    console.log( Object.keys(data).length >0)
    console.log(data)
    if(param.pageNo ===1  ){
      getNftList();
    }
  }, [param]);

  const more = ()=>{ 
    setParam(prevState => { 
      prevState.pageNo++;
      return prevState
    })
    return getNftList();
  }

  const search = async () => {
    setList([])
    const data = form.getFieldsValue(true);
    const sortIteme = sortType.find(item => item.value == (data.sort&& data.sort[0]))||{}
    setParam(prevState => {
      prevState.pageNo=1;
      prevState.id=data.id;
      prevState.name=data.name;
      prevState.level= data.level?.join(',');
      prevState.type= data.type?.join(',');
      prevState.orderBy = data.orderBy?.join(',');
      prevState.orderType = data.orderType?.join(',');
      return {...prevState, ...sortIteme.filter }
    })
    setFilterVisible(false) 
  }


  const reset = async () => {
    form.resetFields();
  }

  const getNftList = async()=>{ 
    toastLoading();
    return await shopApi.shopList(param).then(res => {
        setList( res.data )
        toastHide();
    }).catch(err => {
        toastHide();
    })
  }

  const nftItem=(item)=>{ 
    const rare = item.type === 1?
    rareType.find(items=>items.level === item.level )||rareType.find(items=>items.level === "1") :
    rareType.find(items=>items.level === "1")

    const linkDetail=
      ()=>navigate('/mine/shop/detail',{state:{id:item.id,info:item}});
    return (
      <div className={classNames(styles.grid_item)}
          key={item.id}  onClick={linkDetail}
      >
        <div className={classNames(styles.grid_image)} 
          style={{ "backgroundImage": `url(${item.avatar}),linear-gradient(180deg, #123556 0%, #071A2C 100%)` }} >
          <div className='flex algn-center justify-end text-12 align-center'>
            {item.type=="1" && rare?.tag }
          </div>
        </div>
        <div className='text-left flex flex-direction text-12'>
          <div className="text-16 text-bold">{item.title}</div>
          <div>{item.description}</div>
          <div className={styles.coin} >
            <img src={gap}/>
            {item.bscPrice.toFixed(2)}
          </div>
          <div className={styles.coin}>
            <div className='text-8 text-orange'>积分 </div>
            <div className='text-8 text-orange'>{item.scorePrice.toFixed(2)}</div>
          </div>
        </div>
      </div>
    )
  }
           
  return (
    <CommonPage flex padding0 title={t('lbl_market_list')} 
      header={<CommonNavBar onBack={()=>navigate("/mine")}>{t('lbl_market_list')}</CommonNavBar>}
    >
      
      <div className={styles.mainContent}>
        <div className={styles.filter} > 
          <span>{t("nft.hot")}</span>
          <span className='flex align-center' onClick={()=>setFilterVisible(true)}><IconFilter /></span>
        </div>
        <div className={styles.grid} style={{padding:"16px 0" }}>
          {list.map(item=>nftItem(item))}
        </div>
      </div>
      <CommonPopup visible={filterVisible} getVisible={setFilterVisible} footer={
        <div className={styles.action}>
          <CommonButton cancel onClick={reset}>{t("common.reset")}</CommonButton>
          <CommonButton className={styles.confirm} onClick={search}>{t('common.confirm')}</CommonButton>
        </div>
          }>
        <div className='popupContent'>
          <Form className={styles.form} form={form} >
            <div className='popupContent'>
              <Form.Item label={t("nft.sort")} name="sort">
                <CommonSelector columns={2} options={sortType}></CommonSelector>
              </Form.Item>
            </div>
          </Form>
        </div>
      </CommonPopup>
    </CommonPage>
  );
})  
