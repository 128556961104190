import Page from "./Page";
import Invitation from "../pages/mine/invitation/Invitation";
import Wallet from "../pages/mine/wallet/Wallet";
import Withdraw from "../pages/mine/wallet/withdraw/Withdraw";
import Recharge from "../pages/mine/wallet/recharge/Recharge";
import Record from "../pages/mine/wallet/record/Record";
import Shop from "../pages/mine/shop/Shop";
import Address from "../pages/mine/address/Address";
import Order from "../pages/mine/shop/order/Order";
import Detail from "../pages/mine/shop/Detail";
import Buy from "../pages/mine/shop/Buy";
import Create from "../pages/mine/address/Create";
import Exchange from "../pages/mine/wallet/exchange/Exchange";
import Community from "../pages/mine/community/Community";
import CommunitySettings from "../pages/mine/community/settings/Settings";
import CommunitySearch from "../pages/mine/community/search/Search";

const mineRoute = [
  Page('我的邀请码', '/mine/invitation', <Invitation/>),
  Page('我的钱包', '/mine/wallet', <Wallet/>),
  Page('提现', '/mine/wallet/withdraw', <Withdraw/>),
  Page('充值', '/mine/wallet/recharge', <Recharge/>),
  Page('记录', '/mine/wallet/record', <Record/>),
  Page('兑换', '/mine/wallet/exchange', <Exchange/>),
  Page('我的社区', '/mine/community', <Community/>),
  Page('社区设置', '/mine/community/setting', <CommunitySettings/>),
  Page('查询级别业绩', '/mine/community/search', <CommunitySearch/>),
  Page('在线商城', '/mine/shop', <Shop/>),
  Page('收货地址', '/mine/address', <Address/>),
  Page('我的订单', '/mine/shop/order', <Order/>),
  Page('详情', '/mine/shop/detail', <Detail/>),
  Page('购买', '/mine/shop/buy', <Buy/>),
  Page('新增地址', '/mine/address/create', <Create/>),
]

export default mineRoute;
